import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

interface SaleData {
  date: string;
  owner: string;
  winner: string;
  token_id: string;
  euro_value: string;
  dollar_value: string;
  eth_value: string;
}

interface LastSalesProps {
  talentName: string;
  tokenId?: string;
  url_picture: string; // Optional tokenId
}

const LastSales: React.FC<LastSalesProps> = ({ talentName, tokenId, url_picture }) => {
  const [salesData, setSalesData] = useState<SaleData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const currency = localStorage.getItem('pxlCurrency') || 'EUR'; // Fetch currency from localStorage (EUR by default)

  const { t } = useTranslation();
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        // Construct the API URL with talent_name and optionally token_id
        let apiUrl = `${linkUrl}/wp-json/custom/v1/get-talent-sales/?talent_name=${talentName}`;
        if (tokenId) {
          apiUrl += `&token_id=${tokenId}`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();

        if (!response.ok) throw new Error('Failed to fetch sales data');
        setSalesData(data); // Store the sales data
      } catch (err) {
        setError(t('errorFetchingSalesDataLastSales'));
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [talentName, tokenId]); // Refetch data when talentName or tokenId changes


  const getRelativeTime = (dateString: string) => {
    const saleDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - saleDate.getTime();
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (minutesDiff < 60) {
      return minutesDiff <= 1 ? 'Just now' : `${minutesDiff} minutes ago`;
    } else if (hoursDiff < 24) {
      return hoursDiff === 1 ? '1 hour ago' : `${hoursDiff} hours ago`;
    } else if (daysDiff < 7) {
      return daysDiff === 1 ? '1 day ago' : `${daysDiff} days ago`;
    } else {
      return saleDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
  };





  if (loading) {
    return <p>{t('loadingComponentA')}</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="last-sales">
      {salesData
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) // Sort most recent first
        .map((sale) => (
         
         
         <div key={sale.token_id} className="sale-item">
           
            <div className="sale-details">
            
           
            <span className="sale-details-container">
               
            <img className="sale-image" src={url_picture} alt={talentName} />
            
            <span className="sale-details-header">
            <span className="sale-token">
                {t('token')}: {sale.token_id}
            </span>
            <span className="sale-date">
              {getRelativeTime(sale.date)}
            </span>
            </span>

            </span>



              <span className="sale-price">
                <span className="sale-price-currency">
                {currency === 'USD'
                  ? `$${sale.dollar_value}`
                  : `€${sale.euro_value}`}
                </span>
                <span className="sale-price-eth">
                {sale.eth_value} ETH
                </span>
              </span>

              
         
              
            </div>
          </div>
        ))}
    </div>
  );
};

export default LastSales;
